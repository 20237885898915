.container {
  text-align: center;
  color: 	#30572b;
  background-color: #E3B448;
  width: 500px;
  margin: 50px;
  border: 2px solid #30572b;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  position: absolute;
  top: 10%;
  left: 30%;
}

.heading{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  margin-top: 50px;
  font-weight: 500;
}
.image{
  width: 200px;
  height: 200px;
  background: #fff;
  border: 2px solid white;
  border-radius: 50%;
  margin: 10px;
}
.name{
  color: 	#30572b;
  font-size: large;
}
.email{
  color: 	#30572b;
  font-size: large;
}
.btn{
  width: 200px;
  height: 50px;
  text-align: center;
  background-color: #CBD18F;
  color: #30572b;
  margin: 10px;
  font-size: large;
  font-weight: 800;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  cursor: pointer;
}
.btn:hover{
  background-color: #30572b;
  color: #CBD18F
}